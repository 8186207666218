.SectionThreeMob {
  background-image: url("../../../assets/bg3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.SectionThreeMob::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/border4.png") no-repeat;
  background-size: contain;
  pointer-events: none;
}

@media (max-width: 392px) {
  .SectionThreeMob {
    height: 135% !important;
  }
}
