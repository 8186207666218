div#hs-eu-cookie-confirmation {
  background: #fff;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100000000 !important;
  border-bottom: 1px solid #cbd6e2;
  border-top: 1px solid #cbd6e2;
  box-shadow: 0 1px 5px #eaf0f6;
  color: #33475b;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400 !important;
  line-height: inherit;
  text-align: left;
  text-shadow: none !important;
  font-size: 12px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 18px;
}
div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
  position: fixed;
  border-bottom: 0;
  bottom: 0;
  top: auto;
  box-shadow: 0 -1px 3px #eaf0f6;
}
div#hs-eu-cookie-confirmation * {
  box-sizing: border-box !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
  background: #fff;
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a {
  text-decoration: none !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a,
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a:hover {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: #0091ae;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400 !important;
  line-height: inherit;
  text-align: left;
  text-shadow: none !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a:hover {
  text-decoration: underline !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording {
  margin-bottom: 12px;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area,
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-cookie-confirmation-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
  margin-right: 72px;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 800px) {
  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
    justify-content: center;
    margin-right: 0;
  }
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-cookie-confirmation-button-group {
  justify-content: center;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button,
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-cookie-settings-button,
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-decline-button {
  margin: 6px !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button,
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-decline-button {
  border-radius: 3px;
  display: inline-block;
  padding: 10px 16px !important;
  text-decoration: none !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button {
  background-color: #1d2024 !important;
  border: 1px solid #1d2024 !important;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400 !important;
  line-height: inherit;
  text-align: left;
  text-shadow: none !important;
  cursor: pointer;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-decline-button {
  border: 1px solid #1d2024 !important;
  color: #1d2024;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400 !important;
  line-height: inherit;
  text-align: left;
  text-shadow: none !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-cookie-settings-button {
  color: #1d2024 !important;
}
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
  margin: 0 72px 12px;
  color: #33475b;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400 !important;
  line-height: inherit;
  text-align: left;
  text-shadow: none !important;
}
@media (max-width: 800px) {
  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    margin: 0 20px 12px;
  }
}
#hs-eu-close-button-container {
  display: flex;
  justify-content: end;
  margin-top: 8px;
  margin-right: 8px;
}
#hs-eu-close-button-container a#hs-eu-close-button[role="button"] {
  float: right;
  width: 1.5rem;
  font-size: 40px !important;
  text-align: center !important;
  cursor: pointer;
  color: #8b8589 !important;
}
#hs-eu-close-button-container a#hs-eu-close-button[role="button"]:hover {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: #0091ae;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400 !important;
  line-height: inherit;
  text-align: left;
  text-shadow: none !important;
  text-decoration: none !important;
}
@media (max-width: 800px) {
  #hs-eu-close-button-container a#hs-eu-close-button[role="button"] {
    margin-right: 10px;
    font-size: 30px;
    line-height: 50px;
  }
}
@media (min-width: 800px) {
  #hs-eu-close-button-container a#hs-eu-close-button[role="button"] {
    margin-bottom: 10px;
  }
}
@media print {
  div#hs-eu-cookie-confirmation {
    display: none !important;
  }
}
@media screen and (max-width: 480px) {
  div#hs-eu-cookie-confirmation {
    font-size: 12px !important;
  }
  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
    padding: 8px 14px 14px !important;
  }
  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a,
  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner a#hs-eu-confirmation-button {
    font-size: 12px !important;
  }
  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    font-size: 12px !important;
    margin-bottom: 12px !important;
    line-height: 15px !important;
  }
}
@media only screen and (min-width: 960px) {
  div#hs-eu-cookie-confirmation {
    position: fixed;
  }
}
