@mixin responsive-padding($min, $ideal, $max)
    padding-top: $min

    @media (min-width: 600px)
        padding-top: $ideal

    @media (min-width: 1920px)
        padding-top: $max

.SectionThreeMob
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    padding-top: 100px
    padding-bottom: 100px
    .body
        display: flex
        flex-direction: column
        .subbody
            padding: 0 16px
            @include responsive-padding(7.2vw, 7.2vw, 138px)
        .SectionThreeLegends
            .img-legends
                margin: 0 auto
                width: 80vw
                max-width: 1128px
                width: 100%
                @include responsive-padding(5vw, 5vw, 20px)
    .footer
        @include responsive-padding(1vw, 1vw, 19px)
        display: flex
        justify-content: center
    &-btn
        display: flex
        justify-content: center
        margin-top: 50px

.SectionThreeMob
    .SectionThreeLegends
        .item-legends
            .name
                font-size: 14px

@media (max-width: 820px)
    .SectionThreeMob
        .SectionThreeLegends
            width: 50%
            display: flex
            flex-wrap: wrap
            .item-legends
                min-width: 100px
                .name
                    font-size: 12px
                img
                    width: 100% !important
                    max-width: 100% !important
                    display: block
                    padding: 0
                    margin: 0
                    height: 180px
                    object-fit: cover
                    object-position: top
@media (max-width: 700px)
    .SectionThreeMob
        .SectionThreeLegends
            width: 70%

@media (max-width: 600px)
    .SectionThreeMob
        .SectionThreeLegends
            width: 80%

@media (max-width: 500px)
    .SectionThreeMob
        .SectionThreeLegends
            width: 90%
