.Header
    padding: 0px
    max-width: 1920px
    position: fixed
    z-index: 100
    width: 100%
    top: 0px
    height: 256px
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 36.77%, rgba(0, 0, 0, 0.00) 80.23%)
    @media (max-width: 1400px)
        height: 128px

    .container
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0px 32px

    .logo
        cursor: pointer
        svg
            margin-top: 0
            width: 100%
            max-width: 320px!important

    .btns
        display: flex
        justify-content: center
        gap: 16px
        opacity: 1
        transition: opacity 0.5s

@media (min-width: 1400px)
    .Header
        .container
            padding: 36px 67px

@media (max-width: 1024px)
    .Header
        .container
            display: flex
            justify-content: center
            .logo
                svg
                    margin-top: -30px
                    width: 250px!important

